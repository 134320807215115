import React from "react";
import Splash from "./routes/Splash";
import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import Home from "./routes/Home";
import Profile from "./routes/Profile";
import Radio from "./routes/Radio";
import Circle from "./routes/Circle";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import ResetPassword from "./routes/ResetPassword";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Splash Pages */}
        <Route path="/" element={<Splash />} />
        <Route path="/memberships" element={<Splash page={"memberships"} />} />
        <Route
          path="/fiddle-school"
          element={<Splash page={"fiddle-school"} />}
        />
        <Route path="/mastery" element={<Splash page={"mastery"} />} />
        <Route path="/retreats" element={<Splash page={"retreat"} />} />
        <Route path="/about" element={<Splash page={"about"} />} />

        {/* Home Pages */}
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/courses" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/tracks" element={<Radio />} />
        <Route path="/circle" element={<Circle />} />
      </Routes>
    </div>
  );
}

export default App;
