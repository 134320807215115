import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Dots} from "react-activity";
import {checkSubscriptionStatus} from "../firebase";
import "react-activity/dist/library.css";
import "../css/Login.css";

export default function ResetPasswordModal(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayIndicator, setDisplayIndicator] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState("password");




    const sendResetPasswordEmail = async email => {
        const query = `
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      userErrors {
        field
        message
      }
    }
  }
`;

        const variables = {email}; // Ganti dengan email pelanggan.

        try {
            const response = await fetch("https://c4x3xq-e0.myshopify.com/api/2023-04/graphql.json", {
                method: "POST", headers: {
                    "Content-Type": "application/json",
                    "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN,
                }, body: JSON.stringify({query, variables}),
            });

            const jsonResponse = await response.json();

            if (!response.ok) {
                throw new Error(jsonResponse.errors?.[0]?.message || "An error occurred");
            }

            const {userErrors} = jsonResponse.data.customerRecover;

            if (userErrors.length > 0) {
                console.error("Errors:", userErrors);
            } else {
                alert("Reset password email sent successfully.");
            }
        } catch (error) {
            alert("Error sending reset password request:"+ error.message);
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Panggil fungsi customerRecover di sini
        try {
            await sendResetPasswordEmail(email);
            alert("Check your email for reset instructions.");
        } catch (error) {
            alert("Error: " + error.message);
        }
    };



    return (<div className="container">
            <div className="header">
                <p className="login-title">Reset Password</p>
            </div>

            <div className="input-group">
                <p className="label">Email</p>
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-field"
                />
            </div>

            {/*<div className="input-group">*/}
            {/*  <p className="label">Password</p>*/}
            {/*  <div className="password-container">*/}
            {/*    <input*/}
            {/*      value={password}*/}
            {/*      onChange={(e) => setPassword(e.target.value)}*/}
            {/*      type={showPassword === "text" ? "text" : "password"}*/}
            {/*      className="input-field"*/}
            {/*    />*/}
            {/*    <div*/}
            {/*      onClick={() =>*/}
            {/*        setShowPassword(showPassword === "password" ? "text" : "password")*/}
            {/*      }*/}
            {/*      className="password-toggle"*/}
            {/*    >*/}
            {/*      {showPassword === "text" ? (*/}
            {/*        <FaEye color="black" size={18} />*/}
            {/*      ) : (*/}
            {/*        <FaEyeSlash color="black" size={18} />*/}
            {/*      )}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="login-button" onClick={handleSubmit}>
                <p className="button-text">Reset Password</p>
                {displayIndicator && (<div className="indicator">
                        <Dots color="white"/>
                    </div>)}
            </div>

            <p className="signup-text">
                Back to login page?&nbsp;&nbsp;
                <Link to="/login" className="signup-link">
                    <span className="signup-underline">Log In</span>
                </Link>
            </p>
        </div>);
}
