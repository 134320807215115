import React, { useEffect } from "react";

const Calendar = ({ isOpen, onClose, isGolden }) => {
  if (!isOpen) return null;
  return (
    <>
      <div className="modal-background">
        <div
          className="modal-container"
          style={{
            height: "80%",
            width: "85vw",
            maxWidth: 950,
            position: "relative",
          }}
        >
          <h2 className="modal-title">Calendar</h2>
          <div style={{ height: "90%", marginTop: 20 }}>
            {isGolden ? (
              <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FLos_Angeles&src=YTQ3Y2Y2NWRkYWRlZDRlNjYyNjQ2MTM2YzFlMmU4YmU1YTQzMDRhMjZkYTA4ZjFmZjYxOGQyNjQ1ODBkMTQ2NUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%238E24AA"
                style={{ border: "1px solid #777" }}
                width={"95%"}
                height="90%"
                frameborder="0"
                scrolling="no"
              />
            ) : (
              <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FLos_Angeles&src=ZjkxNGZmYzA1NGFhOTkwNTAzM2U0ZTBjMDI4YjZhNzNlMGQ1NmM5NzIxMGUwMTkyMzI4YmVhODU0NzQxNTk2MkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D81B60"
                style={{ border: "1px solid #777" }}
                width={"95%"}
                height="90%"
                scrolling="no"
              ></iframe>
            )}
          </div>
          <div style={{ position: "absolute", bottom: 40, right: 0, left: 0 }}>
            <button
              className="modal-button-close modal-button"
              onClick={onClose}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
