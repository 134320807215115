import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import "../css/GoldenFiddle.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_TOKEN,
  authDomain: "fiddleschool-6fa40.firebaseapp.com",
  projectId: "fiddleschool-6fa40",
  storageBucket: "fiddleschool-6fa40.appspot.com",
  messagingSenderId: "997131554190",
  appId: "1:997131554190:web:1b16cfa7a7e5b7a72868df",
  measurementId: "G-THQHHBHPFB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const GoldenFiddle = ({ isOpen, onClose, setSelectedKit, changeCourse }) => {
  const [contentData, setContentData] = useState({});
  const [currentClasses, setCurrentClasses] = useState([]);
  const fetchContentData = async () => {
    try {
      const contentData = {};

      // Define the main collection
      const sections = [
        "FiddleTuneFrontiers",
        "SwingImprovWorkouts",
        "TexasTunes",
        "TheoryJammingSkills",
        "Guitar",
        "Other",
      ];

      // Loop through each section
      for (const section of sections) {
        const sectionCollection = collection(
          db,
          "goldenFiddle",
          section,
          "items"
        );
        const querySnapshot = await getDocs(sectionCollection);

        // Map documents into an array
        const sectionData = querySnapshot.docs.map((doc) => doc.data());

        // Add the section data back into the contentData object
        contentData[section] = sectionData;
      }

      setContentData(contentData);
      return contentData;
    } catch (error) {
      console.error("Error fetching content data:", error);
    }
  };

  const fetchCurrentClasses = async () => {
    try {
      // Reference the `goldenFiddle/currentClasses` document
      const docRef = doc(db, "goldenFiddle", "currentClasses");

      // Fetch the document snapshot
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();

        // Convert the restructured data back into a nested array
        const currentClasses = Object.keys(data)
          .sort((a, b) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1])) // Sort by section index
          .map((key) => data[key]); // Extract arrays in order
        return currentClasses;
      } else {
        console.log("No such document in goldenFiddle collection!");
      }
    } catch (error) {
      console.error("Error fetching currentClasses:", error);
    }
  };

  useEffect(() => {
    // Usage
    fetchContentData().then((contentData) => {
      setContentData(contentData);
    });

    fetchCurrentClasses().then((currentClasses) => {
      setCurrentClasses(currentClasses);
    });
  }, []);

  const currentClassesSections = [
    "Fiddle Tune Frontiers",
    "Swing Improv Workouts",
    "Texas Tunes",
  ];

  const currentClasses1 = [
    [
      {
        name: "Performance and the Inner Game with Adam Kulakow",
        youtubeLink:
          "https://www.youtube.com/playlist?list=PLUJz4_--pzvKcNzc0BQ4AL3CDbGXGHnsW",
        googleDriveLink:
          "https://drive.google.com/drive/folders/1xANXpmUj648W3Tcj8VdDk1qu86wmFzQc",
      },
      {
        name: "The Fiddle Tune Block System: Girl I Left Behind Me",
        youtubeLink: "",
        googleDriveLink: "",
      },
      // {
      //   name: "Seasonal Swing Concert & Play-Along",
      //   youtubeLink: "",
      //   googleDriveLink: "",
      // },
    ],
    [
      {
        name: "Draggin' the Bow",
        youtubeLink:
          "https://www.youtube.com/playlist?list=PLUJz4_--pzvKfY56QESQKSAWOlaEMkOW3",
        googleDriveLink:
          "https://drive.google.com/drive/folders/1xANXpmUj648W3Tcj8VdDk1qu86wmFzQc",
      },
    ],
    [
      {
        name: "Walk Along John",
        youtubeLink:
          "https://www.youtube.com/playlist?list=PLUJz4_--pzvJ5iUqYLhQGRrFJkNhjiI_0",
        googleDriveLink:
          "https://drive.google.com/drive/folders/1xANXpmUj648W3Tcj8VdDk1qu86wmFzQc",
      },
    ],
  ];
  let globalIndex = 17;
  const renderSection = (sectionName, items) => (
    <div key={sectionName}>
      <h3
        className="section-title"
        style={{
          textAlign: "left",
          marginLeft: 20,
          fontSize: 22,
          marginTop: sectionName != "SwingImprovWorkouts" ? 50 : 20,
        }}
      >
        {sectionName.replace(/([A-Z])/g, " $1").trim()}
      </h3>
      <ul className="section-list" style={{ marginTop: 20, marginBottom: 20 }}>
      {items
        .sort((a, b) => a.name.localeCompare(b.name)) // Sort items alphabetically by name
        .map((item,index) => {
          const currentIndex = globalIndex; // Simpan index saat ini
          globalIndex++; // Tingkatkan index global untuk item berikutnya
          return (
            <li
              key={index}
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-start",
                textAlign: "left",
                paddingLeft: 20,
              }}
            >
              {index+1}.&nbsp;
              <div style={{ flex: 1, marginRight: 20 }}>
                {item.name}
              </div>
              <a
                href={item.youtubeLink}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Video
              </a>
              <span className="link-divider">|</span>
              <a
                href={item.googleDriveLink}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Handouts
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );

  if (!isOpen) return null; // Only render if open
  return (
    <div className="modal-background">
      <div
        className="modal-container"
        style={{
          height: "85%",
          width: "85vw",
          maxWidth: 950,
          position: "relative",
        }}
      >
        <h2 className="modal-title">Golden Fiddle Portal</h2>
        {isOpen == "archive" ? (
          <>
            <div
              style={{
                height: "80%",
                overflow: "scroll",
                marginTop: 10,
              }}
            >
              {contentData ? (
                Object.keys(contentData).map((section) =>
                  renderSection(section, contentData[section])
                )
              ) : (
                <></>
              )}
            </div>
            <div
              style={{ position: "absolute", bottom: 40, right: 0, left: 0 }}
            >
              <button
                className="modal-button-close modal-button"
                onClick={onClose}
              >
                Go Back
              </button>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div
              style={{
                height: "85%",
                overflow: "scroll",
                marginTop: 10,
              }}
            >
              Class Videos Will Be Posted Here As They Come Out
              {currentClassesSections.map((item, index) => {
                return (
                  <>
                    <div
                      className="section-title"
                      style={{ textAlign: "left" }}
                    >
                      {item}
                    </div>
                    <ul
                      className="section-list"
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      {currentClasses[index].map((item, index0) => {
                        // setIndexNow(indexNow+1)
                        return (
                          <li
                            key={index0}
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "flex-start",
                              textAlign: "left",
                              paddingLeft: 20,
                            }}
                          >
                            {index0 + 1}.&nbsp;
                            <div
                              onClick={() => {
                                changeCourse(item.slug, true);
                                setSelectedKit(
                                  (index === 0 ? 0 : index === 1 ? 2 : 3) +
                                    index0 +
                                    13
                                );
                                onClose();
                              }}
                              style={{ flex: 1, marginRight: 20 }}
                              className="golden-fiddle-currentclasses-link"
                            >
                              {item.name}
                            </div>
                            {/* {item.youtubeLink.length > 0 ? (
                            <div>
                              <a
                                href={item.youtubeLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link"
                              >
                                Youtube
                              </a>
                              <span className="link-divider">|</span>
                              <a
                                href={item.googleDriveLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link"
                              >
                                Handouts
                              </a>
                            </div>
                          ) : (
                            <div>Coming Soon...</div>
                          )} */}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                );
              })}
            </div>
            <div
              style={{ position: "absolute", bottom: 40, right: 0, left: 0 }}
            >
              <button
                className="modal-button-close modal-button"
                onClick={onClose}
              >
                Go Back
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GoldenFiddle;
