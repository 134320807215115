import React, { useEffect } from "react";
import NavBarHome from "../components/NavBarHome";
import "../App.css";

export default function Circle(props) {
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email == "null" || !email) {
      window.location.href = "/login";
    }
  }, []);
  return (
    <>
      <div className="screen-container">
        <NavBarHome page={"circle"} />
        <iframe
          style={{ marginTop: 105 }}
          className="splash-iframe"
          src="https://fiddle-school.circle.so/"
        />
      </div>
    </>
  );
}
