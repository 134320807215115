import React from "react";
import LogInModal from "../components/LogInModal";
import NavBar from "../components/NavBar";
import "../css/Login.css";
import ResetPasswordModal from "../components/ResetPasswordModal";

export default function ResetPassword(props) {
  return (
    <>
      <div className="login-container">
        <NavBar />
          <ResetPasswordModal/>
      </div>
    </>
  );
}
