import React from "react";

const RadioSidebar = ({
  stations,
  selectedStation,
  onSelectStation,
  setSelectedTrack,
}) => {
  return (
    <div className="sidebarRadio">
      <h2 className="sidebar-title">Stations</h2>
      {stations.map((station, index) => (
        <div
          key={index}
          className={`station-item-radio ${
            selectedStation === index ? "selected" : ""
          }`}
          onClick={() => {
            onSelectStation(index);
            setSelectedTrack(0);
          }}
        >
          {station}
        </div>
      ))}
    </div>
  );
};

export default RadioSidebar;
