import React from "react";
import HeaderImageWhite from "../assets/fiddleschool-logo.png";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "../css/Login.css";

export default function NavBar(props) {
  const navigate = useNavigate();
  function toggleMenu() {
    const navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("active");
  }

  return (
    <>
      <div className="navbar" style={{ position: "relative" }}>
        <div className="navImage">
          <div
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              className="navbar-image"
              src={HeaderImageWhite}
              style={{
                cursor:
                  window.location.href.split("/")[2].length == 0
                    ? "auto"
                    : "pointer",
              }}
            />
          </div>
        </div>
        <div
          class="hamburger"
          onClick={() => {
            toggleMenu();
          }}
        >
          ☰
        </div>

        <div className="nav-item-container nav-item-container-splash">
          <div className="nav-item-container-1">
            <div
              onClick={() => {
                navigate("/fiddle-school");
              }}
              className={
                props.page == "fiddle-school" ? "nav-item-selected" : "nav-item"
              }
            >
              Fiddle School
            </div>
            <div
              onClick={() => {
                navigate("/memberships");
              }}
              className={
                props.page == "memberships" ? "nav-item-selected" : "nav-item"
              }
            >
              Memberships
            </div>
            <a
              target="_blank"
              href="https://www.fiddleschoolmastery.com/"
              className={"nav-item no-decoration"}
            >
              Mastery
            </a>
            <div
              onClick={() => {
                navigate("/retreats");
              }}
              className={
                props.page == "retreats" ? "nav-item-selected" : "nav-item"
              }
            >
              Retreats
            </div>
          </div>
          <div className="nav-item-container-2">
            <div
              onClick={() => {
                navigate("/about");
              }}
              className={
                props.page == "about" ? "nav-item-selected" : "nav-item"
              }
            >
              About Us
            </div>
            <a
              target="_blank"
              href="https://fiddleschool.store"
              className={"nav-item no-decoration"}
            >
              Shop
            </a>
          </div>
        </div>
        <div
          onClick={() => navigate("/login")}
          className="splash-button splash-login-button"
        >
          <p className="Header-selected">Log In</p>
        </div>
      </div>
      <nav class="nav-links">
        <div className="nav-item-container-1-small">
          <div
            onClick={() => {
              toggleMenu();
              navigate("/fiddle-school");
            }}
            className={
              props.page == "fiddle-school"
                ? "nav-item-selected nav-item-small"
                : "nav-item nav-item-small"
            }
          >
            Fiddle School
          </div>
          <div
            onClick={() => {
              toggleMenu();
              navigate("/memberships");
            }}
            className={
              props.page == "memberships"
                ? "nav-item-selected nav-item-small"
                : "nav-item nav-item-small"
            }
          >
            Memberships
          </div>
          <a
            target="_blank"
            onClick={() => {
              toggleMenu();
            }}
            href="https://www.fiddleschoolmastery.com/"
            className={"nav-item nav-item-small no-decoration"}
          >
            Mastery
          </a>
          <div
            onClick={() => {
              toggleMenu();
              navigate("/retreats");
            }}
            className={
              props.page == "retreats"
                ? "nav-item-selected nav-item-small"
                : "nav-item nav-item-small"
            }
          >
            Retreats
          </div>
        </div>
        <div className="nav-item-container-2-small">
          <div
            onClick={() => {
              toggleMenu();
              navigate("/about");
            }}
            className={
              props.page == "about"
                ? "nav-item-selected nav-item-small"
                : "nav-item nav-item-small"
            }
          >
            About Us
          </div>
          <a
            target="_blank"
            onClick={() => {
              toggleMenu();
            }}
            href="https://fiddleschool.store"
            className={
              props.page == "shop"
                ? "nav-item-selected nav-item-small no-decoration"
                : "nav-item nav-item-small no-decoration"
            }
          >
            Shop
          </a>
        </div>
        <div
          onClick={() => {
            navigate("/login");
            toggleMenu();
          }}
          className="splash-button splash-login-button-small"
        >
          <p className="Header-selected">Log In</p>
        </div>
      </nav>
    </>
  );
}
