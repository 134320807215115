import React, { useState, useEffect } from "react";
import HeaderImage from "../assets/Logo.png";
import DownArrow from "../assets/down-arrow.png";

import "../App.css";
import SignUpModal from "../components/SignUpModal";

export default function SignUp(props) {
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: 100 }}>
          <SignUpModal />
        </div>
      </div>
    </>
  );
}
