import { useNavigate } from "react-router-dom";
import HeaderImageWhite from "../assets/fiddleschool-logo.png";
import Hamburger from "../assets/Hamburger.png";

export default function NavBarHome(props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="navbar">
        {props.page == "courses" ? (
          <div className="sidebarToggle">
            <div
              onClick={() => {
                props.toggleSidebar();
              }}
            >
              <img
                src={Hamburger}
                style={{ width: 32, height: 32, cursor: "pointer" }}
              />
            </div>
            <div
              onClick={() => {
                navigate("/courses");
              }}
            >
              <img
                src={HeaderImageWhite}
                style={{
                  width: 135,
                  objectFit: "cover",
                }}
                className={"navImage"}
              />
            </div>
          </div>
        ) : (
          <div
            className="navImage"
            onClick={() => {
              navigate("/courses");
            }}
            style={{ flex: 1 }}
          >
            <img
              src={HeaderImageWhite}
              style={{
                cursor: "pointer",
              }}
              className="navbar-image"
            />
          </div>
        )}

        <div className="nav-item-logout-small">
          <div
            onClick={() => {
              localStorage.setItem("email", null);
              window.location.href = "/login";
            }}
            className="splash-login-button splash-button navbar-home-logout"
          >
            Log out
          </div>
        </div>
        <div className="nav-item-container nav-item-container-splash">
          <div className="nav-item-container-1">
            <div
              className={
                props.page == "courses" ? "nav-item-selected" : "nav-item"
              }
              onClick={() => {
                navigate("/courses");
              }}
            >
              Courses
            </div>
            <div
              onClick={() => {
                navigate("/tracks");
              }}
              className={
                props.page == "radio" ? "nav-item-selected" : "nav-item"
              }
            >
              Tracks & Playlists
            </div>
          </div>
          <div className="nav-item-container-2">
            <div
              onClick={() => {
                navigate("/circle");
              }}
              className={
                props.page == "circle" ? "nav-item-selected" : "nav-item"
              }
            >
              Community
            </div>
            <a
              target="_blank"
              href="https://fiddleschool.store"
              className={"nav-item no-decoration"}
            >
              Shop
            </a>
            <div>
              <div
                onClick={() => {
                  localStorage.setItem("email", null);
                  window.location.href = "/login";
                }}
                className="splash-login-button splash-button navbar-home-logout"
              >
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nav-links-visible-home-container">
        <nav className="nav-links-visible nav-links-visible-home">
          <div className="nav-item-container-1-small">
            <div
              onClick={() => {
                navigate("/courses");
              }}
              className={
                props.page == "courses" ? "nav-item-selected" : "nav-item"
              }
            >
              Courses
            </div>
            <div
              onClick={() => {
                navigate("/tracks");
              }}
              className={
                props.page == "radio" ? "nav-item-selected" : "nav-item"
              }
            >
              Tracks & Playlists
            </div>
            <div
              onClick={() => {
                navigate("/circle");
              }}
              className={
                props.page == "circle" ? "nav-item-selected" : "nav-item"
              }
            >
              Community
            </div>
            <a
              target="_blank"
              onClick={() => {}}
              href="https://fiddleschool.store"
              className={"nav-item nav-item-small no-decoration"}
            >
              Shop
            </a>
          </div>
        </nav>
      </div>
    </>
  );
}
