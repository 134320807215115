import React, { useState, useEffect, useRef } from "react";
import { FaPlay, FaPause, FaForward, FaBackward } from "react-icons/fa";
import WaveSurfer from "wavesurfer.js";
import "../App.css";

const PlayTrack = ({
  onPlayPause,
  onNext,
  onPrevious,
  tuneFile,
  track,
  selectedNumberTrack,
  onSelectedTrack,
  onDownloadUrlTrack
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [countdown, setCountdown] = useState(3); // Countdown timer
  const [startCountdown, setStartCountdown] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [countdownToggle, setCountdownToggle] = useState(true);

  const waveSurferRef = useRef(null);
  const waveContainerRef = useRef(null);

  // Initialize WaveSurfer
  useEffect(() => {
    const initializeWaveSurfer = async () => {
      try {
             // Destroy existing audio instance before loading a new track
      setCurrentTime(0);
        waveSurferRef.current = WaveSurfer.create({
          container: waveContainerRef.current,
          waveColor: "#7b2d28",
          progressColor: "#c0c0c0",
          height: 150,
          responsive: true,
          audioRate: speed,
          xhr: {
            cache: "default",
            mode: "cors",
            method: "GET",
            credentials: "include",
            headers: [
              { key: "cache-control", value: "no-cache" },
              { key: "pragma", value: "no-cache" },
            ],
          },
        });

        if (onSelectedTrack) {
          waveSurferRef.current.load(onSelectedTrack);
        }

        waveSurferRef.current.on("ready", () => {
          setDuration(waveSurferRef.current.getDuration());
        });

        waveSurferRef.current.on("audioprocess", () => {
          setCurrentTime(waveSurferRef.current.getCurrentTime());
        });

        waveSurferRef.current.on("error", (error) => {
          console.error("WaveSurfer error:", error);
        });
      } catch (error) {
        console.error("Error initializing WaveSurfer:", error.message);
      }
    };

    initializeWaveSurfer();

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, [onSelectedTrack, speed]);
  useEffect(() => {
    if (countdown > 0 && startCountdown) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setShowOverlay(false); // Hide overlay when countdown ends
      waveSurferRef.current.play(); // Start playing the track
      setIsPlaying(true);
      setStartCountdown(false);
    }
  }, [countdown]);
  const handlePlayPause = () => {
    setStartCountdown(true);
    if (countdownToggle == false) {
      waveSurferRef.current.playPause();
      setIsPlaying(waveSurferRef.current.isPlaying());
      // Start countdown when track is loaded
    } else if (isPlaying == true) {
      waveSurferRef.current.pause();
      setIsPlaying(waveSurferRef.current.isPlaying());
    } else if (waveSurferRef.current) {
      // waveSurferRef.current.playPause();
      setIsPlaying(waveSurferRef.current.isPlaying());
      // Start countdown when track is loaded
      setShowOverlay(true);
      setCountdown(5);
    }
  };

  const handleProgressChange = (event) => {
    const newTime = parseFloat(event.target.value);
    setCurrentTime(newTime);
    if (waveSurferRef.current) {
      waveSurferRef.current.seekTo(newTime / duration);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };
  return (
    <>
      <div
        style={{
          flex: 1,
          height: 150,
        }}
        ref={waveContainerRef}
      />
      <div className="control-bar-play-along">
        {showOverlay && (
          <div
            className={`countdown-overlay ${countdown === 0 ? "fade-out" : ""}`}
          >
            <h1>{countdown > 0 ? countdown : ""}</h1>
          </div>
        )}
        <div className="speed-control-container-play">
          <div className="speed-input">
            <label htmlFor="speed-input" className="slider-label">
              Speed
            </label>
            <input
              id="speed-input"
              type="number"
              min="0.5"
              max="2"
              step="0.05"
              value={speed}
              onChange={(e) => setSpeed(parseFloat(e.target.value))}
              className="input-box"
            />
          </div>
        </div>
        <div>
          <button className="control-btn" onClick={onPrevious}>
            <FaBackward />
          </button>
          <button className="control-btn play-pause" onClick={handlePlayPause}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button className="control-btn" onClick={onNext}>
            <FaForward />
          </button>
          <div className="progress-container-track">
            <span className="current-time">{formatTime(currentTime)}</span>
            <input
              type="range"
              className="progress-bar-track"
              min="0"
              max={duration}
              value={currentTime}
              onChange={handleProgressChange}
            />
            <span className="total-time">{formatTime(duration)}</span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#70322A",
            color: "white",
            height: 50,
            width: 100,
            borderRadius: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            cursor: "pointer",
            opacity: countdownToggle ? 1 : 0.7,
            transition: "0.5s",
            position: "absolute",
            right: 25,
          }}
          className="countdown-play"
          onClick={() => {
            setCountdownToggle(!countdownToggle);
          }}
        >
          {countdownToggle ? "Countdown Off" : "Countdown On"}
        </div>
      </div>
    </>
  );
};

export default PlayTrack;
