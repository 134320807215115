import React, { useState, useEffect, useRef } from "react";
import {
  FaPlay,
  FaPause,
  FaForward,
  FaBackward,
  FaRandom,
  FaRedo,
} from "react-icons/fa"; // Font Awesome Icons
import "../App.css";
import RadioWave from "./RadioWave";
import Play from "../assets/play.png";
import Pause from "../assets/pause.png";
// import WaveSurfer from "wavesurfer.js";
import WaveSurfer from "wavesurfer.js";
import { useNavigate } from "react-router-dom";
import { S3, GetObjectCommand } from "@aws-sdk/client-s3";

const s3Client = new S3({
  forcePathStyle: false, // Configures to use subdomain/virtual calling format.
  endpoint: "https://nyc3.digitaloceanspaces.com",
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_DIGITAL_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_DIGITAL_SECRET_ACCESS_KEY,
  },
});
const PlayAlong = ({
  onPlayPause,
  onNext,
  onPrevious,
  onShuffle,
  onReplay,
  tuneFile, // total duration in seconds
  lesson,
  selectedPlayAlong,
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [countdown, setCountdown] = useState(3); // Countdown timer
  const [showOverlay, setShowOverlay] = useState(false); // Controls overlay visibility

  // Convert seconds to "minutes:seconds" format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleProgressChange = (event) => {
    const newTime = parseFloat(event.target.value);
    setCurrentTime(newTime);
    if (waveSurferRef.current) {
      waveSurferRef.current.seekTo(newTime / duration); // Seek based on percentage
    }
  };

  const [speed, setSpeed] = useState(1);
  const [playPause, setPlayPause] = useState(false);
  const [current, setCurrent] = useState(null);
  const waveSurferRef = useRef(null);

  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const getObj = async () => {
    const command = new GetObjectCommand({
      Bucket: "play-along-tracks",
      Key: selectedPlayAlong
        ? "lesson" + lesson + "/" + selectedPlayAlong
        : "lesson1/04 Boil The Cabbage Fast - Guitar.mp3",
    });
    try {
      const response = await s3Client.send(command);
      // The Body object also has 'transformToByteArray' and 'transformToWebStream' methods.
      const test = await response.Body.transformToString("base64");
      setFile(test);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    // console.log(selectedPlayAlong);
    const val = getObj();
  }, [lesson, selectedPlayAlong]);

  // Initialize WaveSurfer once when the component mounts
  useEffect(() => {
    waveSurferRef.current = WaveSurfer.create({
      container: waveSurferRef.current,
      waveColor: "#7b2d28",
      progressColor: "#c0c0c0",
      height: 50,
      width: "45vw",
      responsive: true,
    });

    // Clean up WaveSurfer instance on unmount
    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, []);

  // Handle track changes by loading new audio source and resetting play state
  useEffect(() => {
    if (file && waveSurferRef.current) {
      // Destroy existing audio instance before loading a new track
      waveSurferRef.current.destroy();
      setCurrentTime(0);
      // Re-create the WaveSurfer instance with the new audio source
      waveSurferRef.current = WaveSurfer.create({
        container: "#Wave",
        waveColor: "#7b2d28",
        progressColor: "#c0c0c0",
        height: 150,
        responsive: true,
        audioRate: speed,
      });

      setDuration(waveSurferRef.current.getDuration());
      // Load the new track
      waveSurferRef.current.load("data:audio/wav;base64," + file);

      // Update current time as the track plays
      waveSurferRef.current.on("audioprocess", () => {
        setCurrentTime(waveSurferRef.current.getCurrentTime());
      });
      // Reset play state and speed
      setIsPlaying(false);
    }
  }, [file]);
  const [startCountdown, setStartCountdown] = useState(false);
  const [countdownToggle, setCountdownToggle] = useState(true);

  useEffect(() => {
    if (countdown > 0 && startCountdown) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setShowOverlay(false); // Hide overlay when countdown ends
      waveSurferRef.current.play(); // Start playing the track
      setIsPlaying(true);
      setStartCountdown(false);
    }
  }, [countdown]);

  const handlePlayPause = () => {
    setStartCountdown(true);
    if (countdownToggle == false) {
      waveSurferRef.current.playPause();
      setIsPlaying(waveSurferRef.current.isPlaying());
      // Start countdown when track is loaded
    } else if (isPlaying == true) {
      waveSurferRef.current.pause();
      setIsPlaying(waveSurferRef.current.isPlaying());
    } else if (waveSurferRef.current) {
      // waveSurferRef.current.playPause();
      setIsPlaying(waveSurferRef.current.isPlaying());
      // Start countdown when track is loaded
      setShowOverlay(true);
      setCountdown(5);
    }
  };

  // Add this effect to handle speed changes
  useEffect(() => {
    if (waveSurferRef.current) {
      waveSurferRef.current.setPlaybackRate(speed);
    }
  }, [speed]); // Runs every time `speed` changes

  // Example input and slider handlers
  const handleSpeedChange = (e) => {
    const newSpeed = parseFloat(e.target.value);
    if (newSpeed >= 0.5 && newSpeed <= 2) {
      setSpeed(newSpeed); // Update the state
    }
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          height: 150,
        }}
        id="Wave"
        ref={waveSurferRef}
      />
      <div className="control-bar-play-along">
        {showOverlay && (
          <div
            className={`countdown-overlay ${countdown === 0 ? "fade-out" : ""}`}
          >
            <h1>{countdown > 0 ? countdown : ""}</h1>
          </div>
        )}
        <div
          style={{
            position: "absolute",
            left: "6vw",
            minWidth: 300,
            textAlign: "left",
          }}
        ></div>
        <div className="speed-control-container-play">
          <div className="speed-input">
            <label htmlFor="speed-input" className="slider-label">
              Speed
            </label>
            <input
              id="speed-input"
              type="number"
              min="0.5"
              max="2"
              step="0.05"
              value={speed}
              onChange={handleSpeedChange}
              className="input-box"
            />
            <div className="slider-wrapper">
              <input
                id="speed-slider"
                type="range"
                min="0.5"
                max="2"
                step="0.05"
                value={speed}
                onChange={handleSpeedChange}
                className="vertical-slider"
              />
            </div>
          </div>
        </div>
        <div>
          <button className="control-btn" onClick={onPrevious}>
            <FaBackward />
          </button>

          <button
            className="control-btn play-pause"
            onClick={() => {
              handlePlayPause();
            }}
          >
            <FaPause style={{ display: isPlaying ? "flex" : "none" }} />
            <FaPlay style={{ display: isPlaying ? "none" : "flex" }} />
          </button>

          <button className="control-btn" onClick={onNext}>
            <FaForward />
          </button>
          <div className="progress-container-track">
            <span className="current-time">{formatTime(currentTime)}</span>
            <input
              type="range"
              className="progress-bar-track"
              min="0"
              max={duration}
              value={currentTime}
              onChange={handleProgressChange}
            />
            <span className="total-time">{formatTime(duration)}</span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#70322A",
            color: "white",
            height: 50,
            width: 100,
            borderRadius: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            cursor: "pointer",
            opacity: countdownToggle ? 1 : 0.7,
            transition: "0.5s",
            position: "absolute",
            right: 25,
          }}
          className="countdown-play"
          onClick={() => {
            setCountdownToggle(!countdownToggle);
          }}
        >
          {countdownToggle ? "Countdown Off" : "Countdown On"}
        </div>
      </div>
    </>
  );
};

export default PlayAlong;
