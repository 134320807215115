import React, { useEffect, useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
  setDoc,
  addDoc,
} from "firebase/firestore/lite";
import { GetObjectCommand, S3 } from "@aws-sdk/client-s3";
import { Dots } from "react-activity";
import GoldenFiddlePng from "../assets/GoldenFiddle.png";
import MasteryPortalPng from "../assets/MasteryPortal.png";
import CongratulationsModal from "./Congratulations";
import GoldenFiddle from "./GoldenFiddle";
import PlayAlong from "./PlayAlong";
import PlayTrack from "./PlayTrack";
import Calendar from "./Calendar";
import LessonVideos from "./LessonVideos";
import TracksModal from "./TracksModal";
import NavBarHome from "./NavBarHome";
import "react-activity/dist/library.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "../App.css";
import "../css/Course.css";
import CurrentClassesTracksModal from "./CurrentClassesTracksModal";
import downloadIcon from "../assets/downloads.png";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const s3Client = new S3({
  forcePathStyle: false, // Configures to use subdomain/virtual calling format.
  endpoint: "https://nyc3.digitaloceanspaces.com",
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_DIGITAL_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_DIGITAL_SECRET_ACCESS_KEY,
  },
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_TOKEN,
  authDomain: "fiddleschool-6fa40.firebaseapp.com",
  projectId: "fiddleschool-6fa40",
  storageBucket: "fiddleschool-6fa40.appspot.com",
  messagingSenderId: "997131554190",
  appId: "1:997131554190:web:1b16cfa7a7e5b7a72868df",
  measurementId: "G-THQHHBHPFB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

function Course(props) {
  const [videos, setVideos] = useState(null);
  const [calendar, setCalendar] = useState(null);
  const [handout, setHandout] = useState(null);
  const [handoutMobile, setHandoutMobile] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentUnit, setCurrentUnit] = useState(0);
  const [currentUnitDisplay, setCurrentUnitDisplay] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [playAlongTracks, setPlayAlongTracks] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [selectedPlayAlong, setSelectedPlayAlong] = useState(0);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [courses, setCourses] = useState([]);
  const [libraries, setLibraries] = useState([]);
  const [videoSelection, setVideoSelection] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLessonVideosOpen, setIsLessonVideosOpen] = useState(false);
  const [isTracksOpen, setIsTracksOpen] = useState(false);
  const [isCurrentClassesTracksOpen, setIsCurrentClassesTracksOpen] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [goldenFiddleOpen, setGoldenFiddleOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [numberOfUnits, setNumberOfUnits] = useState([0, 0, 0, 0]);
  const [videoIndex, setVideoIndex] = useState(0);
  const [currentVideo, setCurrentVideo] = useState("video1");
  const [currentSelectedUnit, setCurrentSelectedUnit] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedKit, setSelectedKit] = useState(0);
  const [browseKit, setBrowseKit] = useState(0);
  const [currentSlug, setCurrentSlug] = useState("starterKit");
  const [lessonStart, setLessonStart] = useState(0);
  const [lessonUnits, setLessonUnits] = useState(null);
  const [lesson, setLesson] = useState(0);
  const [key, setKey] = useState(0);
  const [goldenMembers, setGoldenMembers] = useState(0);
  const [masteryMembers, setMasteryMembers] = useState({});
  const [masteryMembersAvailable, setMasteryMembersAvailable] = useState(false);
  const [file, setFile] = useState(null);
  const [lessonVideoProgress, setLessonVideoProgress] = useState([]);
  const [hasMembership, setHasMembership] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedNumberTrack, setSelectedNumberTrack] = useState(0);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [downloadUrlTrackFile, setDownloadUrlTrackFile] = useState(null);
  const [handoutSpecial, setHandoutSpecial] = useState(null);
  const [selectedHandoutSpecialVideo, setSelectedHandoutSpecialVideo] =
    useState(0);
  const [selectedHandoutSpecialHandout, setSelectedHandoutSpecialHandout] =
    useState(0);
  const [selectedHandoutSpecialFile, setSelectedHandoutSpecialFile] =
    useState(0);
  const [selectedHandout, setSelectedHandout] = useState({
    name: "",
    url: "",
    urlMobile: "",
  });
  const [lessonSection, setLessonSection] = useState(0);
  const sidebarPlayRef = useRef(null);
  const sidebarRef = useRef(null);

  const handleDownloadFile = async (file) => {
    const filePath = file; // Ganti dengan path file di Firebase Storage
    const fileName = decodeURIComponent(
      filePath.split("/").pop().split("?")[0]
    ); // Nama file saat diunduh

    try {
      // Dapatkan URL file dari Firebase Storage
      const fileRef = ref(storage, filePath);
      const fileUrl = await getDownloadURL(fileRef);

      // Fetch file dari Firebase Storage
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("File not found");
      }

      // Convert response menjadi Blob
      const blob = await response.blob();

      // Membuat URL untuk Blob
      const url = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.download = fileName; // Nama file untuk diunduh
      tempLink.click();

      // Bersihkan URL objek setelah digunakan
      window.URL.revokeObjectURL(url);
      console.log("File berhasil didownload:", fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
 
  const classTypes = [
    { name: "Starter Kit", slug: "starterKit" },
    {
      name: "Restarter Kit",
      slug: "restarterKit",
    },
    { name: "Tune-up Kit", slug: "tuneUpKit" },
    {
      name: "Session 1: Fiddle Foundations",
      slug: "sessionOne",
    },
    { name: "Session 2: Reach Your Next Level ", slug: "sessionTwo" },
    {
      name: "Session 3: The Advancing Fiddler",
      slug: "sessionThree",
    },
    { name: "Session 4: The Seasoned Fiddler", slug: "sessionFour" },
    {
      name: "Mastery 1",
      slug: "masteryPortal",
    },
    {
      name: "Mastery 2",
      slug: "masteryPortal2",
    },
    {
      name: "Mastery 3",
      slug: "masteryPortal3",
    },
    {
      name: "Mastery 4",
      slug: "masteryPortal4",
    },
    {
      name: "Mastery 4x",
      slug: "masteryPortal4x",
    },
    {
      name: "Mastery Plus",
      slug: "masteryPortalPlus",
    },
    {
      name: "Performance and the Inner Game with Adam Kulakow",
      slug: "performanceAndtheInnerGamewithAdamKulakow",
    },
    {
      name: "The Fiddle Tune Block System: Girl I Left Behind Me",
      slug: "theFiddleTuneBlockSystem:GirlILeftBehindMe",
    },
    {
      name: "Draggin' the Bow",
      slug: "DraggintheBow",
    },
    {
      name: "Walk Along John",
      slug: "WalkAlongJohn",
    },
    {
      name: "Holiday Favorites and Holiday Concert",
      slug: "HolidayFavoritesandHolidayConcert",
    },
    {
      name: "Performance and the Inner Game with Adam Kulakow",
      slug: "performanceAndtheInnerGamewithAdamKulakowLibrary",
    },
  ];

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const getObj = async () => {
    let command;
    if (selectedKit == 0 || selectedKit == 1 || selectedKit == 2) {
      command = new GetObjectCommand({
        Bucket: "play-along-tracks",
        Key: "handouts/" + classTypes[selectedKit].slug + ".pdf",
      });
    } else {
      command = new GetObjectCommand({
        Bucket: "play-along-tracks",
        Key: true
          ? "lesson" +
            (lessonStart + currentUnit + 1) +
            "/lesson" +
            (lessonStart + currentUnit + 1) +
            "handout.pdf"
          : "lesson1/lesson1handout.pdf",
      });
    }
    try {
      const response = await s3Client.send(command);
      // The Body object also has 'transformToByteArray' and 'transformToWebStream' methods.
      const blob = await response.Body.transformToByteArray();
      const pdfUrl = URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      setFile(pdfUrl);
    } catch (err) {
      console.error(err);
    }
  };

  function toggleSidebar() {
    document.querySelector("#sidebar").classList.toggle("closed");
    document.querySelector("#video-container").classList.toggle("closed");
    setSidebarOpen(!sidebarOpen);
  }

  const getCourseByName = (courses, name) => {
    let coursesFromName = courses.filter((item) => item.name == name);
    return coursesFromName[0];
  };

  const retrieveCourses = async (currentUnit) => {
    const courses = await getCourse(db);
    setCourses(courses);
    let course = getCourseByName(courses, "starterKit");
    const units = Object.keys(course.data.units).length - 1;
    const unitString = "unit" + (currentUnit + 1);
    setVideos(course.data.units[unitString]);
    setIsLoadingVideo(false);
    getProgressVideo("starterKit", "unit1", course.data.units[unitString]);
  };
  const changeCourse = (slug, introKit) => {
    setCurrentSlug(slug);

    let course = getCourseByName(courses, slug);

    if (course) {
      if (introKit == false) {
        let units = Array(course.data.lessonAmount).fill(0);
        setCurrentSelectedUnit(0);
        setCurrentUnit(course.data.lessonStart);
        setCurrentUnitDisplay(course.data.lessonStart);
        let lessonVidSelection = course.data["video"];
        let vids = lessonVidSelection["practiceBuddies"];
        setVideos(vids);
        setNumberOfUnits(units);
        setLessonStart(course.data.lessonStart);
        if (true) {
          let videoString = "video";
          if (currentUnit != 0) videoString += currentUnit + 1;
          setLessonUnits(course.data.lessons["lesson1"].sections);

          if (course.data[videoString]) {
            setPlayAlongTracks(course.data[videoString].playAlong);
          } else {
            setPlayAlongTracks(course.data["video"].playAlong);
          }
          setSelectedPlayAlong(0);
        }
        getProgressVideo(slug, "video", vids);
      } else {
        const units = Object.keys(course.data.units).length - 1;
        setNumberOfUnits(Array(units).fill(0));
        setCurrentUnit(0);
        setVideos(course.data.units["unit1"]);
        getProgressVideo(slug, "unit1", course.data.units["unit1"]);
        setCalendar(course.data.calendar);
        if (course.data.tracks) {
          setTracks(course.data.tracks);
        }
        if (course.data.specialHandout) {
          setHandoutSpecial(course.data.specialHandout);
        }
        setHandout(course.data.handout);
        if (course.data.handout > 1) {
          setSelectedHandout(course.data.handout[0]);
        }
        setHandoutMobile(course.data.handoutMobile);
        setCurrentUnit(0);
      }
    } else {
      alert("course is not avalaible now");
    }
  };

  const handleDownloadFileTeack = async (file) => {
    const filePath = file; // Ganti dengan path file di Firebase Storage
    const fileName = decodeURIComponent(
      filePath.split("/").pop().split("?")[0]
    ); // Nama file saat diunduh

    try {
      // Dapatkan URL file dari Firebase Storage
      const fileRef = ref(storage, filePath);
      const fileUrl = await getDownloadURL(fileRef);

      // Fetch file dari Firebase Storage
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("File not found");
      }

      // Convert response menjadi Blob
      const blob = await response.blob();

      // Membuat URL untuk Blob
      const url = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.download = fileName; // Nama file untuk diunduh
      tempLink.click();

      // Bersihkan URL objek setelah digunakan
      window.URL.revokeObjectURL(url);
      console.log("File berhasil didownload:", fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getTrackFromFirebase = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then(async (blob) => {
        blobToBase64(blob).then((b64) => {
          setSelectedTrack(b64);
        });
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (tracks) {
      getTrackFromFirebase(tracks[0]?.url);
      setDownloadUrlTrackFile(tracks[0]?.url);
    }
  }, [tracks]);


  const changeUnit = (unit) => {
    let course = getCourseByName(courses, currentSlug);
    const unitString = "unit" + (unit + 1);
    setVideos(course.data.units[unitString]);
    getProgressVideo(currentSlug, unitString, course.data.units[unitString]);
  };

  const changeLesson = (unit, vids) => {
    let course = getCourseByName(courses, currentSlug);
    const lessonString = "lesson" + (unit + 1);
    setLessonUnits(course.data.lessons[lessonString].sections);
    let videoString = "video";
    if (currentUnit != 0) videoString += currentUnit + 1;
    setSelectedPlayAlong(0);
    setSelectedTab(0);
  };

  async function getCourse(db) {
    const courseCollection = collection(db, "courses");
    const courseSnapshot = await getDocs(courseCollection);
    const courseList = courseSnapshot.docs.map((doc) => {
      return { name: doc.id, data: doc.data() };
    });
    return courseList;
  }

  const scrollSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollBy({
        top: 60, // Scrolls down 60px vertically
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  useEffect(() => {
    if (currentUnit != null) {
      retrieveCourses(currentUnit);
    }
  }, []);
  useEffect(() => {
    if (handout && handout.length > 1) {
      setSelectedHandout((prevState) => ({
        ...prevState,
        name: handout[0]?.name,
        url: handout[0]?.url,
        urlMobile: handout[0]?.urlMobile,
      }));
    }
  }, [handout]);
  useEffect(() => {
    setVideoIndex(0);
    setCurrentVideo("video1");
    setIsLoadingVideo(false);
    getObj();
  }, [videos]);

  useEffect(() => {
    setVideos(videos);
  }, []);
  useEffect(() => {
    const hasGoldenFiddleMembership = (orders) => {
      return orders?.some((order) =>
        order.lineItems.edges.some(
          (lineItem) => lineItem.node.title === "Golden Fiddle Membership"
        )
      );
    };

    const savedOrders = JSON.parse(
      localStorage.getItem("customerOrders") || "[]"
    );

    const fetchMembers = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, "goldenFiddle", "members");
        const docSnap = await getDoc(docRef);

        const docRef2 = collection(db, "memberMastery");
        const docSnap2 = await getDocs(docRef2);

        docSnap2.docs.map((docu) => {
          const data = docu.data();
          setMasteryMembers((prev) => ({
            ...prev,
            [data.path]: data.emails,
          }));
        });

        const userEmail = localStorage.getItem("email");
        let emails = [];
        if (!userEmail) {
          console.error("No email found in localStorage");
          return;
        }

        if (docSnap.exists()) {
          let data = docSnap.data();
          setGoldenMembers(data.emails || []); // Accessing the `emails` array
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    //   setHasMembership(hasGoldenFiddleMembership(savedOrders));
    //   // setHasMembership();
    // if (hasGoldenFiddleMembership(savedOrders)) {

    // }
    const checkMembership = async () => {
      const db = getFirestore();
      const docRef = collection(db, "shopify_transaction_logs");
      const qCM = query(docRef, where("status", "==", "false"));

      const docSnap = await getDocs(qCM);

      docSnap.docs.map(async (doca) => {
        const data = doca.data();
        const names = data.product_name.split(",");
        const ids = data.id.toString().split(",");
        var n = [];

        ids.map((item) => {
          if (!n.includes(item)) {
            n.push(item);
          }
        });

        n.map(async (item) => {
          if (item == 8647166329089) {
            //mastery 1-4x
            const docRef2 = collection(db, "memberMastery");
            const qCM2 = query(docRef2, where("name", "in", names));

            const docSnap2 = await getDocs(qCM2);

            docSnap2.docs.map(async (docu) => {
              const a = doc(db, "memberMastery", docu.id);
              await updateDoc(a, {
                emails: arrayUnion(data.email),
              });
            });
          } else if (item == 8646393266433) {
            // id golden
            const docRef2 = doc(db, "goldenFiddle", "members");
            updateDoc(docRef2, {
              emails: arrayUnion(data.email),
            });
          } else {
            const docRef2 = collection(db, "memberMastery");
            const qCM2 = query(
              docRef2,
              where("id", "in", ids),
              where("id", "!=", 8647166329089),
              where("id", "!=", 8646393266433)
            );

            const docSnap2 = await getDocs(qCM2);

            docSnap2.docs.map(async (docu) => {
              const a = doc(db, "memberMastery", docu.id);
              await updateDoc(a, {
                emails: arrayUnion(data.email),
              });
            });
          }
        });

        const docRef3 = doc(db, "shopify_transaction_logs", doca.id);
        await updateDoc(docRef3, {
          status: "true",
        });
      });
    };
    checkMembership().then(() => {
      fetchMembers();
    });
  }, [hasMembership]);

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email == "null" || !email) {
      window.location.href = "/login";
    } else setUserEmail(email);
  }, []);


  const setProgressVideo = async (slug, videoId) => {
    const video = lessonNow;
    const db = getFirestore();
    const docRef = collection(db, "usersProgress");
    const up = query(docRef, where("email", "==", userEmail));
    const sn = await getDocs(up);
    let create = true;
    sn.docs.map(async (docu) => {
      create = false;
      const docRefId = doc(db, "usersProgress", docu.id);
      await updateDoc(docRefId, {
        [`${slug}.${video}`]: arrayUnion({
          id: videoId,
          listen_on: new Date().getTime(),
        }),
      });
    });
    if (create) {
      addDoc(docRef, {
        email: userEmail,
        [`${slug}`]: {
          [`${video}`]: arrayUnion({
            id: videoId,
            listen_on: new Date().getTime(),
          }),
        },
      });
    }
  };

  const [lessonNow, setLessonNow] = useState("");
  const getProgressVideo = async (slug, video, videos) => {
    setLessonNow(video);
    let email = localStorage.getItem("email");

    const db = getFirestore();
    const docRef = collection(db, "usersProgress");
    const up = query(docRef, where("email", "==", email));
    const sn = await getDocs(up);
    sn.docs.map((doc) => {
      const data = doc.data();
      if (data[slug]) {
        if (data[slug][video]) {
          let prog = {};
          data[slug][video].forEach((item) => {
            prog[item["id"]] = true;
          });
          let arr = [];
          Object.keys(videos).map((item, index) => {
            if (typeof prog[videos[item]["id"]] !== "undefined") {
              videos[item]["completed"] = true;
              arr.push(true);
            } else {
              arr.push(false);
            }
          });
          setVideos(videos);
          setLessonVideoProgress(arr);
        }
      }
    });
  };

  // Detect the screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window?.innerWidth]);
  // async function saveVideoProgress(userId, lessonId, videoId, completed) {
  //   const progressRef = doc(db, "userProgress", userId);

  //   try {
  //     // Prepare video data
  //     const videoData = {
  //       [`${lessonId}.videos.${videoId}`]: {
  //         completed: completed,
  //         timestamp: completed ? new Date().toISOString() : null,
  //       },
  //     };

  //     // Check if the document exists
  //     const docSnap = await getDoc(progressRef);
  //     if (!docSnap.exists()) {
  //       // Initialize the structure
  //       await setDoc(
  //         progressRef,
  //         { [lessonId]: { videos: {} } },
  //         { merge: true }
  //       );
  //     }

  //     // Update or create video progress
  //     await updateDoc(progressRef, videoData);
  //   } catch (error) {
  //     console.error("Error saving video progress:", error);
  //   }
  // }
  return (
    <>
      <div className="course-container">
        <NavBarHome page="courses" toggleSidebar={toggleSidebar} />
        <div id="sidebar" className="closed">
          <div style={{ flexDirection: "row", display: "flex", height: 10 }}>
            <div
              onClick={() => {
                toggleSidebar();
              }}
              className="close-toggle course-close-toggle"
            >
              x
            </div>
          </div>{" "}
          <nav className="course-sidebar-nav" style={{ marginBottom: 100 }}>
            {goldenMembers && userEmail && goldenMembers.includes(userEmail) ? (
              <div
                style={{
                  width: 300,
                }}
                className="course-golden-container"
              >
                <div>
                  <img
                    src={GoldenFiddlePng}
                    style={{
                      width: "100%",
                      padding: 0,
                      marginTop: 18,
                      borderTop: "none",
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    setGoldenFiddleOpen("current");
                  }}
                  className="Button course-golden-current"
                  style={{ color: "rgba(231, 177, 88)" }}
                >
                  Current Classes
                </div>
                <div
                  onClick={() => {
                    setGoldenFiddleOpen("archive");
                  }}
                  className="Button course-golden-library"
                >
                  The Library
                </div>
              </div>
            ) : (
              <></>
            )}
            {userEmail &&
            classTypes
              .slice(7, 13)
              .some((item) =>
                masteryMembers[item.slug]?.includes(userEmail)
              ) ? (
              <>
                {/* Bagian Mastery Portal */}
                <div
                  style={{
                    width: 300,
                  }}
                  onClick={() => {
                    changeCourse("masteryPortal", true);
                  }}
                >
                  {masteryMembersAvailable && (
                    <img
                      src={MasteryPortalPng}
                      style={{
                        width: "100%",
                        padding: 0,
                        marginTop: 18,
                        borderTop: "none",
                      }}
                    />
                  )}
                </div>

                <div>
                  {classTypes.slice(7, 13).map((item, index) => {
                    const hasAccess =
                      masteryMembers[item.slug]?.includes(userEmail); // Check mastery access
                    if (hasAccess) {
                      if (!masteryMembersAvailable) {
                        setMasteryMembersAvailable(true);
                      }

                      // if (masteryMembers[item.slug]){
                      //     if (masteryMembers[item.slug].includes(userEmail)) {
                      //       if (!masteryMembersAvailable){
                      //         setMasteryMembersAvailable(true)
                      //       }
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor:
                                selectedKit == index
                                  ? "#51789f"
                                  : "transparent",
                              color: selectedKit == index ? "white" : "black",
                              borderRadius: 8, // display: ?'block':'none',
                              cursor: "pointer",
                              marginTop: 10,
                            }}
                            onClick={() => {
                              changeCourse(item.slug, true);
                              setSelectedKit(index + 7);
                              toggleSidebar();
                            }}
                          >
                            <a>{item.name}</a>
                          </div>
                        </>
                      );
                    }
                    // }

                    return <></>;
                  })}
                </div>
              </>
            ) : (
              <></>
            )}

            <br />
            <br />
            <div className="course-courses-text">Courses</div>
            <ul>
              {classTypes.slice(3, 7).map((item0, index0) => {
                return (
                  <>
                    <li
                      key={index0 + 3}
                      style={{
                        backgroundColor:
                          selectedKit == index0 + 3 ? "#51789f" : "transparent",
                        color: selectedKit == index0 + 3 ? "white" : "black",
                        borderRadius: 8,
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => {
                          changeCourse(item0.slug, false);
                          if (selectedKit < 3) {
                            setSelectedKit(index0 + 3);
                            setBrowseKit(index0 + 3);
                          } else {
                            setSelectedKit(index0 + 3);
                            setBrowseKit(index0 + 3);
                          }
                        }}
                      >
                        {item0.name}
                      </a>
                    </li>
                    {browseKit == index0 + 3 && selectedKit == index0 + 3 ? (
                      <div>
                        {numberOfUnits.map((item, index1) => {
                          return (
                            <div key={index1}>
                              <div
                                onClick={() => {
                                    setLesson(index1);
                                    setVideoIndex(0);
                                    setVideoSelection(0);
                                    setCurrentSelectedUnit(index1);
                                    setCurrentUnit(index1);
                                    setCurrentUnitDisplay(index1);
                                    const value = "practiceBuddies";
                                    let lessonValue = "video";
                                    if (currentSlug == "sessionOne") {
                                      if (index1 == 0) {
                                      } else {
                                        lessonValue += index1 + 1;
                                      }
                                    } else {
                                      if (index1 == 0) {
                                      } else {
                                        lessonValue += index1;
                                      }
                                    }

                                    const courseByName = getCourseByName(
                                      courses,
                                      currentSlug
                                    );

                                    let lessonVidSelection =
                                      courseByName.data[lessonValue];

                                    let vids = lessonVidSelection[value];

                                    setPlayAlongTracks(
                                      courseByName.data[lessonValue].playAlong
                                    );

                                    changeLesson(index1, vids);
                                    setVideos(vids);
                                    getProgressVideo(
                                      currentSlug,
                                      lessonValue,
                                      vids
                                    );
                                  }
                                }
                                style={{
                                  height: 30,
                                  marginTop: 10,
                                  marginLeft: 20,
                                  marginRight: 5,
                                  borderRadius: 6,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <a
                                  style={{
                                    opacity:
                                      currentSelectedUnit == index1 &&
                                      selectedKit == index0 + 3
                                        ? 1
                                        : 0.5,
                                    fontWeight:
                                      currentSelectedUnit == index1 &&
                                      selectedKit == index0 + 3
                                        ? "bold"
                                        : "normal",
                                    zIndex: 25,
                                    cursor: "pointer",
                                    color: "black",
                                    fontSize: 18,
                                    marginBottom: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    textAlign: "left",
                                    width: 240,
                                    height: 30,
                                    paddingLeft: 15,
                                  }}
                                >
                                  Lesson {index1 + lessonStart + 1}
                                </a>
                              </div>
                              {currentUnit == index1 &&
                              selectedKit == index0 + 3 ? (
                                <div>
                                  {lessonUnits.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() => {
                                          if (
                                            videoSelection == index &&
                                            currentSelectedUnit == index1
                                          ) {
                                          } else {
                                            setSelectedKit(index0 + 3);
                                            setBrowseKit(index0 + 3);
                                            setIsLoadingVideo(true);
                                            setVideoSelection(index);
                                            setCurrentSelectedUnit(index1);
                                            setVideoIndex(0);
                                            setLessonSection(index)
                                            const value =
                                              index == 0
                                                ? "practiceBuddies"
                                                : index == 1
                                                ? "listen"
                                                : index == 2
                                                ? "song1"
                                                : index == 3 ? "song2" : "song3";

                                            let lessonValue = "video";
                                            if (lesson == 0) {
                                            } else if (
                                              currentSlug == "sessionOne"
                                            ) {
                                              lessonValue += lesson + 1;
                                            } else {
                                              lessonValue += lesson;
                                            }

                                            const courseByName =
                                              getCourseByName(
                                                courses,
                                                currentSlug
                                              );

                                            let lessonVidSelection =
                                              courseByName.data[lessonValue];

                                            let vids =
                                              lessonVidSelection[value];
                                            if (
                                              lessonVidSelection[value] ==
                                                undefined &&
                                              index == 0
                                            ) {
                                              vids =
                                                lessonVidSelection["rythym"];
                                            } else if (
                                              lessonVidSelection[value] ==
                                                undefined &&
                                              index == 1
                                            ) {
                                              vids =
                                                lessonVidSelection["language"];
                                            }

                                            setVideos(vids);
                                            setIsLoadingVideo(false);
                                            let videoString = "video";
                                            if (currentUnit == 0) {
                                            } else if (selectedKit == 3) {
                                              videoString += currentUnit + 1;
                                            } else {
                                              videoString += currentUnit;
                                            }

                                            setPlayAlongTracks(
                                              courseByName.data[videoString]
                                                .playAlong
                                            );
                                          }
                                          // setVideos(lessonVidSelection[value]);
                                        }}
                                        style={{
                                          backgroundColor:
                                            videoSelection == index &&
                                            currentSelectedUnit == index1 &&
                                            selectedKit == index0 + 3
                                              ? "#68353b"
                                              : "transparent",

                                          height: 30,
                                          marginTop: 10,
                                          marginLeft: 20,
                                          marginRight: 5,
                                          borderRadius: 8,
                                          marginLeft: 50,
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <a
                                          style={{
                                            color:
                                              videoSelection == index &&
                                              currentSelectedUnit == index1 &&
                                              selectedKit == index0 + 3
                                                ? "white"
                                                : "black",
                                            fontSize: 18,
                                            opacity:
                                              videoSelection == index &&
                                              currentSelectedUnit == index1 &&
                                              selectedKit == index0 + 3
                                                ? 1
                                                : 0.5,
                                            fontWeight:
                                              videoSelection == index &&
                                              currentSelectedUnit == index1 &&
                                              selectedKit == index0 + 3
                                                ? "bold"
                                                : "normal",
                                            zIndex: 25,
                                            cursor: "pointer",
                                            marginBottom: 0,
                                            marginTop: 0,
                                            fontSize: 14,
                                            textAlign: "left",
                                            width: 240,
                                            height: 30,
                                            paddingLeft: 15,
                                          }}
                                        >
                                          {index == 0
                                            ? "Instruction"
                                            : lessonUnits[index].name}
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </ul>

            <div>
              {classTypes.slice(0, 3).map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundColor:
                          selectedKit == index ? "#51789f" : "transparent",
                        color: selectedKit == index ? "white" : "black",
                        borderRadius: 8,
                        cursor: "pointer",
                        marginTop: 10,
                      }}
                      onClick={() => {
                        changeCourse(item.slug, true);
                        setSelectedKit(index);
                      }}
                    >
                      <a>{item.name}</a>
                    </div>
                    {selectedKit == index ? (
                      <div>
                        {numberOfUnits.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                setCurrentUnit(index);
                                setCurrentUnitDisplay(index);
                                changeUnit(index);
                              }}
                              style={{
                                backgroundColor:
                                  currentUnit == index
                                    ? "#70323a"
                                    : "transparent",
                                height: 30,
                                marginTop: 10,
                                marginLeft: 20,
                                marginRight: 5,
                                borderRadius: 8,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                style={{
                                  color:
                                    currentUnit == index ? "white" : "black",

                                  opacity: currentUnit == index ? 1 : 0.6,
                                  fontWeight:
                                    currentUnit == index ? "bold" : "normal",
                                  zIndex: 25,
                                  cursor: "pointer",
                                  fontSize: 18,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  fontSize: 14,
                                  textAlign: "left",
                                  width: 240,
                                  height: 30,
                                  paddingLeft: 15,
                                }}
                              >
                                Lesson {index + 1}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>

            <div className="course-calendar-button-container">
              <div
                onClick={() => {
                  setCalendarOpen(true);
                }}
                className="Button course-calendar-button"
              >
                Calendar
              </div>
            </div>
          </nav>
        </div>

        <script src="https://player.vimeo.com/api/player.js"></script>

        <div
          className="fade-in"
          style={{ alignItems: "center" }}
          id={"video-container"}
        >
          {selectedKit >= 7 && selectedKit <= 12 ? (
            <>
              {" "}
              <div
                style={{ width: "90%", textAlign: "left", marginBottom: 30 }}
              >
                <a
                  href="https://us02web.zoom.us/j/87906206117"
                  style={{
                    padding: "10px 50px",
                    borderRadius: 10,
                    backgroundColor: "#51789f",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Zoom Link (Meeting ID: 879 0620 6117)
                </a>
              </div>
            </>
          ) : (
            <></>
          )}

          <div style={{ width: "90%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 0,
                marginBottom: 20,
                width: "100%",
              }}
              className={"course-header"}
            >
              <div
                onClick={() => {
                  setSelectedTab(0);
                }}
                className="Button course-btn"
                style={{
                  width: selectedKit < 3 ? "50%" : "33%",
                  color: selectedTab == 0 ? "white" : "black",
                  backgroundColor: selectedTab == 0 ? "#51789f" : "#eeebe2",
                  fontWeight: selectedTab == 0 ? "bold" : "normal",
                  height: 50,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4,
                  transition: "0.4s",
                }}
              >
                Videos
              </div>

              <div
                className="Button course-btn"
                onClick={() => {
                  setSelectedTab(1);
                }}
                style={{
                  display: selectedKit < 3 || selectedKit > 6 ? "none" : "flex",
                  fontWeight: selectedTab == 1 ? "bold" : "normal",
                  color: selectedTab == 1 ? "white" : "black",
                  backgroundColor: selectedTab == 1 ? "#51789f" : "#eeebe2",
                  borderLeft:
                    selectedTab == 0 ? "none" : "2px solid white !important",
                  borderRight:
                    selectedTab == 2 ? "none" : "2px solid white !important",
                  cursor: "pointer",
                  height: 50,
                  width: "33%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTop: "none",
                  borderBottom: "none",
                  transition: "0.4s",
                }}
              >
                Play Along Tracks
              </div>
              <div
                className="Button course-btn"
                onClick={() => {
                  setSelectedTab(2);
                  setKey((prevKey) => prevKey + 1);
                }}
                style={{
                  display: selectedKit != 15 ? "flex" : "none",
                  width: selectedKit < 3 ? "50%" : "33%",
                  fontWeight: selectedTab == 2 ? "bold" : "normal",
                  backgroundColor: selectedTab == 2 ? "#51789f" : "#eeebe2",
                  color: selectedTab == 2 ? "white" : "black",
                  height: 50,
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  transition: "0.4s",
                }}
              >
                Handout
              </div>
              <div
                className="Button course-btn"
                onClick={() => {
                  setSelectedTab(9);
                }}
                style={{
                  display: selectedKit == 15 ? "flex" : "none",
                  width: selectedKit >= 13 && selectedKit <= 17 ? "50%" : "33%",
                  fontWeight: selectedTab == 9 ? "bold" : "normal",
                  color: selectedTab == 9 ? "white" : "black",
                  backgroundColor: selectedTab == 9 ? "#51789f" : "#eeebe2",
                  height: 50,
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  transition: "0.4s",
                }}
              >
                Handout
              </div>
              <div
                className="Button course-btn"
                onClick={() => {
                  setSelectedTab(8);
                }}
                style={{
                  display:
                    selectedKit >= 7 && selectedKit <= 12 ? "flex" : "none",
                  fontWeight: selectedTab == 1 ? "bold" : "normal",
                  color: selectedTab == 8 ? "white" : "black",
                  backgroundColor: selectedTab == 8 ? "#51789f" : "#eeebe2",
                  borderLeft:
                    selectedTab == 0 ? "none" : "2px solid white !important",
                  borderRight:
                    selectedTab == 2 ? "none" : "2px solid white !important",
                  cursor: "pointer",
                  height: 50,
                  width: "33%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTop: "none",
                  borderBottom: "none",
                  transition: "0.4s",
                }}
              >
                Calendar
              </div>
              {/*#Tracks*/}
              <div
                className="Button course-btn"
                onClick={() => {
                  setSelectedTab(10);
                }}
                style={{
                  display:
                    selectedKit >= 13 && selectedKit <= 21 ? "flex" : "none",
                  fontWeight: selectedTab == 10 ? "bold" : "normal",
                  color: selectedTab == 10 ? "white" : "black",
                  backgroundColor: selectedTab == 10 ? "#51789f" : "#eeebe2",
                  borderLeft:
                    selectedTab == 10 ? "none" : "2px solid white !important",
                  borderRight:
                    selectedTab == 10 ? "none" : "2px solid white !important",
                  cursor: "pointer",
                  height: 50,
                  width: "33%",
                  alignItems: "center",
                  justifyContent: "center",
                  borderTop: "none",
                  borderBottom: "none",
                  transition: "0.4s",
                }}
              >
                Tracks
              </div>
            </div>
            {selectedTab == 0 && (lessonStart != 24 || lesson != 4 || lessonSection != 2) ? (
              <div style={{ width: 1000 }} key={key} className="fade-in">
                <div
                  style={{
                    justifyContent: !sidebarOpen ? "flex-start" : "center",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 40,
                    paddingBottom: 40,
                  }}
                >
                  <div
                    style={{
                      display: !sidebarOpen ? "none" : "block",
                      height: "65vh",
                      overflow: "scroll",
                      width: "30%",
                    }}
                    className={"course-sidebar scrollbar-none"}
                    ref={sidebarRef}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: 10,
                        fontSize: 18,
                      }}
                    >
                      Browse Lesson {currentUnitDisplay + 1} Videos
                    </div>
                    <div className="track-list" style={{ maxWidth: "70%" }}>
                      {videos != null && videos != undefined ? (
                        Object.keys(videos).map((item, index) => {
                          const videoString = "video" + (index + 1);

                          return (
                            <div
                              className="Button track-item"
                              style={{
                                borderWidth: index == videoIndex ? 3.5 : 0,
                                fontWeight:
                                  index == videoIndex ? "700" : "normal",
                                backgroundColor:
                                  selectedKit >= 7 && selectedKit <= 12
                                    ? "#8F150A"
                                    : selectedKit >= 13 && selectedKit <= 21
                                    ? "#efaf46"
                                    : "#51789f",
                                borderRadius: 4,
                                minHeight: 40,
                                marginBottom: 4,
                                marginTop: 4,
                                paddingLeft: 10,
                                paddingRight: 25,
                                alignItems: "center",
                                display: "flex",
                                cursor: "pointer",
                                borderColor: "black",
                                borderStyle: "solid",
                                position: "relative",
                              }}
                              onClick={() => {
                                setCurrentVideo(videoString);
                                setVideoIndex(index);
                              }}
                            >
                              <div style={{ paddingRight: 20 }}>
                                {videos[videoString] &&
                                videos[videoString].title
                                  ? videos[videoString].title
                                  : ""}
                              </div>

                              <div
                                style={{
                                  width: 18,
                                  height: 18,
                                  backgroundColor: "#efefef",
                                  borderRadius: 20,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "absolute",
                                  right: 15,
                                }}
                              >
                                {videos[videoString].completed ? (
                                  <div
                                    style={{
                                      color: "#51789f",
                                      marginTop: 0,
                                      marginBottom: 0,
                                      fontWeight: "bold",
                                      fontSize: 12,
                                      paddingLeft: 4,
                                    }}
                                    className="fade-in"
                                  >
                                    &#x2713;
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="flex-switch-container">
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 20,
                      }}
                      className={"flex-switch"}
                    >
                      <div style={{ width: "70%" }}>
                        <div>
                          <p
                            style={{
                              color: "black",
                              textAlign: "left",
                              marginBottom: 4,
                              marginTop: 0,
                              fontWeight: "bold",
                              fontSize: 18,
                              opacity: 0.4,
                              transition: "height 1s",
                            }}
                          >
                            {classTypes[selectedKit].name}
                          </p>
                        </div>

                        <div>
                          <p className={"Video-title"}>
                            Video {videoIndex + 1} -{" "}
                            {videos &&
                            videos[currentVideo] &&
                            !isLoadingVideo &&
                            videos[currentVideo].title ? (
                              videos[currentVideo].title
                            ) : (
                              <></>
                            )}
                          </p>
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div
                          className="Button course-complete"
                          onClick={() => {
                            let videoString = "video" + (videoIndex + 1);
                            let video = videos;

                            if (
                              typeof video[videoString].completed ===
                              "undefined"
                            ) {
                              setProgressVideo(
                                currentSlug,
                                videos[currentVideo].id
                              );
                            }

                            video[videoString].completed = true;
                            lessonVideoProgress[videoIndex] = true;
                            setVideos(video);
                            if (videoIndex + 1 < Object.keys(videos).length) {
                              setVideoIndex(videoIndex + 1);
                              let videoString = "video" + (videoIndex + 2);
                              if (videoIndex == 0)
                                videoString = "video" + (videoIndex + 2);
                              setCurrentVideo(videoString);
                              scrollSidebar();
                            } else {
                              setIsModalOpen(true);
                            }
                            // if (userId) {
                            //   saveVideoProgress(
                            //     userId,
                            //     "lesson" + (currentUnit + 1),
                            //     "video" + videoIndex,
                            //     true
                            //   );
                            // }
                          }}
                          style={{
                            width: "100%",
                            height: 40,
                            display: "flex",
                            color: "white",
                            cursor: "pointer",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                            backgroundColor: "#792d27",
                            borderRadius: 8,
                          }}
                        >
                          Complete
                        </div>
                        <div
                          onClick={() => {
                            setIsLessonVideosOpen(true);
                          }}
                          className="Button courseModalBtn"
                          style={{
                            display: !sidebarOpen ? "flex" : "none",
                            width: "100%",
                            height: 40,
                            display: "flex",
                            color: "white",
                            cursor: "pointer",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                            backgroundColor: "#5a7793",
                            borderRadius: 8,
                            marginTop: 5,
                          }}
                        >
                          Lesson Videos
                        </div>
                      </div>
                    </div>

                    {videos && lessonVideoProgress ? (
                      <div className="progress-container">
                        <p className="progress-label">
                          Lesson Section Progress:{" "}
                          {lessonVideoProgress.filter((vid) => vid).length} of{" "}
                          {Object.keys(videos).length} Completed
                        </p>
                        <div className="progress-bar">
                          <div
                            className="progress"
                            style={{
                              width:
                                (lessonVideoProgress.filter(
                                  (completed) => completed
                                ).length /
                                  Object.keys(videos).length) *
                                  100 +
                                "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={"flex-switch video-container"}>
                      {videos && videos[currentVideo] && !isLoadingVideo ? (
                        <iframe
                          src={
                            selectedKit >= 17 && selectedKit !== 18
                              ? `https://www.youtube.com/embed/${videos[currentVideo].id}`
                              : `https://player.vimeo.com/video/${videos[currentVideo].id}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`
                          }
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          className={"course-video"}
                          title="Video Player"
                        />
                      ) : (
                        <Dots
                          size={24}
                          style={{ paddingBottom: 125 }}
                          color={"#682e27"}
                        />
                      )}
                    </div>

                    <div className="course-spacer"></div>
                  </div>
                </div>
              </div>
            ) : selectedTab == 1 ? (
              <div className="fade-in video-container course-tracks-container">
                <div className="course-browse-container">
                  <div
                    style={{
                      display: !sidebarOpen ? "none" : "flex",
                    }}
                    className={"course-sidebar course-browse-inner-container"}
                    ref={sidebarPlayRef}
                  >
                    <div className="course-browse-tracks-text">
                      Browse Tracks
                    </div>
                    <div className="track-list course-track-list-container">
                      {playAlongTracks ? (
                        Object.keys(playAlongTracks).map((item, index) => {
                          return (
                            <div
                              className="track-item course-track-item"
                              style={{
                                borderWidth:
                                  index == selectedPlayAlong ? 3.5 : 0,
                                fontWeight:
                                  index == selectedPlayAlong ? "700" : "normal",
                              }}
                              onClick={() => {
                                setSelectedPlayAlong(index);
                              }}
                            >
                              {playAlongTracks[index].name}
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="playback-container">
                    <div>
                      <p className="course-unit-text">
                        {classTypes[selectedKit].name}, Unit 1
                      </p>
                    </div>
                    <div>
                      <p className="track-title course-track-title">
                        Track {selectedPlayAlong + 1}:{" "}
                        {playAlongTracks[selectedPlayAlong].name}
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setIsTracksOpen(true);
                      }}
                      className="Button courseModalBtn course-browse-tracks"
                      style={{
                        display: !sidebarOpen ? "flex" : "none",
                      }}
                    >
                      Browse Tracks
                    </div>
                    <PlayAlong
                      selectedPlayAlong={
                        playAlongTracks[selectedPlayAlong].bucket
                      }
                      isPlaying={isPlaying}
                      lesson={lessonStart + currentUnit + 1}
                      onPlayPause={togglePlayPause}
                      onNext={() => {
                        setSelectedPlayAlong(selectedPlayAlong + 1);
                      }}
                      onPrevious={() => {
                        if (selectedPlayAlong > 0) {
                          setSelectedPlayAlong(selectedPlayAlong - 1);
                        }
                      }}
                      setIsPlaying={setIsPlaying}
                    />
                  </div>
                </div>
              </div>
            ) : selectedTab == 2 ? (
              <div className="fade-in course-pdf-fade-in">
                <div className="course-pdf-container">
                  <div className="course-pdf-inner-container">
                    <div>
                      {typeof handout === "string" ? (
                        (selectedKit >= 7 && selectedKit <= 12) ||
                        (selectedKit >= 13 && selectedKit <= 21 && handout) ? (
                          isMobile ? ( // show when mobile (Google Drive Viewer)
                            <iframe
                              src={`https://docs.google.com/viewer?srcid=${handoutMobile}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                              title="PDF Viewer"
                              width="100%"
                              height="700px"
                              className="course-pdf"
                            /> // show when laptop/desktop (direxctly handout) https://drive.google.com/viewerng/viewer?embedded=true&url=my_url
                          ) : (
                            <iframe
                              src={handout}
                              title="PDF Viewer"
                              width="100%"
                              height="700px"
                              className="course-pdf"
                            />
                          ) // Default
                        ) : (
                          <iframe
                            src={file}
                            title="PDF Viewer"
                            width="100%"
                            height="700px"
                            className="course-pdf"
                          />
                        )
                      ) : (
                        <>
                        {
                          (selectedKit >= 7 && selectedKit <= 12) ||
                          (selectedKit >= 13 &&
                            selectedKit <= 21) ? (
                              <div className="pdf-item-array">
                            {handout?.map((handout, idx) => (
                              <div
                                key={idx}
                                className={`pdf-item-name ${
                                  selectedHandout.url === handout.url
                                    ? "pdf-item-name-active"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedHandout((prevstate) => ({
                                    ...prevstate,
                                    url: handout.url,
                                    urlMobile: handout.urlMobile,
                                  }));
                                }}
                              >
                                {handout?.name}
                              </div>
                            ))}
                          </div>
                            ) : ("")
                        }
                          
                          <div>
                            {(selectedKit >= 7 && selectedKit <= 12) ||
                            (selectedKit >= 13 &&
                              selectedKit <= 21 &&
                              handout) ? (
                              isMobile ? ( // show when mobile (Google Drive Viewer)
                                <iframe
                                  src={`https://docs.google.com/viewer?srcid=${selectedHandout.urlMobile}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                                  title="PDF Viewer"
                                  width="100%"
                                  height="700px"
                                  className="course-pdf"
                                /> // show when laptop/desktop (direxctly handout) https://drive.google.com/viewerng/viewer?embedded=true&url=my_url
                              ) : (
                                <iframe
                                  src={selectedHandout.url}
                                  title="PDF Viewer"
                                  width="100%"
                                  height="700px"
                                  className="course-pdf"
                                />
                              ) // Default
                            ) : (
                              <iframe
                                src={file}
                                title="PDF Viewer"
                                width="100%"
                                height="700px"
                                className="course-pdf"
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : selectedTab == 3 ? (
              <div className="fade-in course-pdf-fade-in">
                <div className="course-pdf-container">
                  <div className="course-pdf-inner-container">
                    <div>
                      <div style={{ marginTop: 20 }}>
                        Meeting ID: 879 0620 6117 <br />
                        <a href="https://us02web.zoom.us/j/87906206117">
                          https://us02web.zoom.us/j/87906206117
                        </a>
                      </div>

                      {/*{file ? (*/}
                      {/*    <iframe*/}
                      {/*        src={file}*/}
                      {/*        title="PDF Viewer"*/}
                      {/*        width="100%"*/}
                      {/*        height="700px"*/}
                      {/*        className="course-pdf"*/}
                      {/*    />*/}
                      {/*) : (*/}
                      {/*    <></>*/}
                      {/*)}*/}
                    </div>
                  </div>
                </div>
              </div>
            ) : selectedTab == 8 ? (
              <div className="fade-in course-pdf-fade-in">
                <div className="course-pdf-container">
                  {/*<div className="course-pdf-inner-container">*/}
                  <div style={{ width: "100%", height: "80vh" }}>
                    <iframe
                      src={calendar}
                      style={{ border: "1px solid #777" }}
                      width={"95%"}
                      height="90%"
                      frameBorder="0"
                      scrolling="no"
                    />
                  </div>
                </div>
              </div>
            ) : selectedTab == 9 ? (
              <div
                className="fade-in"
                style={{ width: "100%", height: "80vh" }}
              >
                {handoutSpecial ? (
                  <>
                    <div className="specialHandoout-layout specialHandoout-margin-top">
                      <h3>Videos</h3>
                      <div className="">
                        <div className="specialHandoout-layout specialHandoout-margin-top-item">
                          {handoutSpecial.videos
                            ? handoutSpecial.videos.map((item, idx) => (
                                <div
                                  key={idx}
                                  className="specialHandoout-layout-button"
                                  onClick={() =>
                                    setSelectedHandoutSpecialVideo(idx)
                                  }
                                  style={{
                                    backgroundColor:
                                      selectedHandoutSpecialVideo == idx
                                        ? "#e7b158"
                                        : "",
                                    color:
                                      selectedHandoutSpecialVideo == idx
                                        ? "white"
                                        : "",
                                  }}
                                >
                                  {item.title}
                                </div>
                              ))
                            : ""}
                        </div>
                      </div>
                      <div className="">
                        <iframe
                          src={
                            "https://player.vimeo.com/video/" +
                            handoutSpecial.videos[selectedHandoutSpecialVideo]
                              .id +
                            "?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                          }
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          className={"course-video"}
                          title="Texas Quick Step - A Part Section 1"
                        />
                      </div>
                    </div>
                    <div className="specialHandoout-layout specialHandoout-margin-top">
                      <h3>Pdf</h3>
                      <div className="">
                        <div className="specialHandoout-layout specialHandoout-margin-top-item">
                          {handoutSpecial.handouts
                            ? handoutSpecial.handouts.map((item, idx) => (
                                <div
                                  key={idx}
                                  className="specialHandoout-layout-button"
                                  onClick={() =>
                                    setSelectedHandoutSpecialHandout(idx)
                                  }
                                  style={{
                                    backgroundColor:
                                      selectedHandoutSpecialHandout == idx
                                        ? "#e7b158"
                                        : "",
                                    color:
                                      selectedHandoutSpecialHandout == idx
                                        ? "white"
                                        : "",
                                  }}
                                >
                                  {item.title}
                                </div>
                              ))
                            : ""}
                        </div>
                      </div>
                      <div className="specialHandoout-layout-item specialHandoout-margin-bottom">
                        {handoutSpecial.handouts ? (
                          isMobile ? ( // show when mobile (Google Drive Viewer)
                            <iframe
                              src={`https://docs.google.com/viewer?srcid=${handoutSpecial.handouts[selectedHandoutSpecialHandout].urlMobile}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                              title="PDF Viewer"
                              width="100%"
                              height="700px"
                              className="course-pdf"
                            /> // show when laptop/desktop (direxctly handout) https://drive.google.com/viewerng/viewer?embedded=true&url=my_url
                          ) : (
                            <iframe
                              src={
                                handoutSpecial.handouts[
                                  selectedHandoutSpecialHandout
                                ].url
                              }
                              title="PDF Viewer"
                              width="100%"
                              height="700px"
                              className="course-pdf"
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <h1>Coming soon</h1>
                  </div>
                )}
              </div>
            ) : selectedTab === 10 ? (
              tracks.length > 0 ? (
                <div className="fade-in video-container course-tracks-container">
                  <div className="course-browse-container">
                    {/* Sidebar */}
                    <div
                      style={{
                        display: sidebarOpen ? "flex" : "none",
                      }}
                      className="course-sidebar course-browse-inner-container"
                      ref={sidebarPlayRef}
                    >
                      <div className="course-browse-tracks-text">
                        Browse Tracks
                      </div>
                      <div className="track-list course-track-list-container">
                        {tracks.map((item, index) => (
                          <div
                            key={index} // Add unique key for mapped items
                            className="track-item course-track-item"
                            style={{
                              backgroundColor:
                                selectedKit >= 13 ? "#e7b158" : "#5a779ce3",
                              borderWidth:
                                index === selectedNumberTrack ? 3.5 : 0,
                              fontWeight:
                                index === selectedNumberTrack
                                  ? "700"
                                  : "normal",
                            }}
                            onClick={() => {
                              setSelectedNumberTrack(index);
                              setSelectedTrack(tracks[index].url);
                              setDownloadUrlTrackFile(tracks[index].url); // Use correct index
                            }}
                          >
                            {item.title}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Playback Container */}
                    <div className="playback-container">
                      <div>
                        <p className="course-unit-text">
                          {classTypes[selectedKit].name}
                        </p>
                        <button
                          onClick={() =>
                            handleDownloadFileTeack(downloadUrlTrackFile)
                          }
                          className="download-item-track specialHandoout-margin-top-item specialHandoout-margin-bottom-item"
                        >
                          <div className="download-file-track">
                            {" "}
                            Download Track
                            <img
                              className="specialHandoout-download-file-img"
                              src={downloadIcon}
                              alt="download"
                            />
                          </div>
                        </button>
                      </div>
                      <div>
                        <p className="track-title course-track-title">
                          Track {selectedNumberTrack + 1}:{" "}
                          {tracks[selectedNumberTrack]?.title || "No Title"}
                        </p>
                      </div>
                      <div
                        onClick={() => setIsCurrentClassesTracksOpen(true)}
                        className="Button courseModalBtn course-browse-tracks"
                        style={{
                          display: sidebarOpen ? "none" : "flex",
                        }}
                      >
                        Browse Tracks
                      </div>
                      <PlayTrack
                        track={classTypes[selectedKit].slug}
                        onDownloadUrlTrack={downloadUrlTrackFile}
                        onSelectedTrack={selectedTrack}
                        isPlaying={isPlaying}
                        lesson={lessonStart + currentUnit + 1}
                        onPlayPause={togglePlayPause}
                        onNext={() => {
                          if (selectedNumberTrack < tracks.length - 1) {
                            setSelectedNumberTrack(selectedNumberTrack + 1);
                          }
                        }}
                        onPrevious={() => {
                          if (selectedNumberTrack > 0) {
                            setSelectedNumberTrack(selectedNumberTrack - 1);
                          }
                        }}
                        setIsPlaying={setIsPlaying}
                      />
                    </div>
                  </div>
                  {handoutSpecial && selectedKit == 15 ? (
                    <div className="specialHandoout-layout specialHandoout-margin-top">
                      <h3>Files</h3>
                      <div className="">
                        <div className="specialHandoout-layout specialHandoout-margin-top-item">
                          {handoutSpecial?.files
                            ? handoutSpecial?.files.map((item, idx) => (
                                <div
                                  key={idx}
                                  className="specialHandoout-layout-button"
                                  onClick={() =>
                                    setSelectedHandoutSpecialFile(idx)
                                  }
                                  style={{
                                    backgroundColor:
                                      selectedHandoutSpecialFile == idx
                                        ? "#e7b158"
                                        : "",
                                    color:
                                      selectedHandoutSpecialFile == idx
                                        ? "white"
                                        : "",
                                  }}
                                >
                                  {item.title}
                                </div>
                              ))
                            : ""}
                        </div>
                      </div>
                      <div className="specialHandoout-layout-item specialHandoout-margin-bottom">
                        {handoutSpecial?.files
                          ? handoutSpecial?.files?.map((item, idx) => (
                              <button
                              key={idx}
                                onClick={()=>handleDownloadFile(item.url)}
                                className="specialHandoout-download-item"
                              >
                                <div className="specialHandoout-download-file">
                                  {" "}
                                  Download {item.title} iReal Pro HTML
                                  <img
                                    className="specialHandoout-download-file-img"
                                    src={downloadIcon}
                                    alt="download"
                                  />
                                </div>
                              </button>
                            ))
                          : ""}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div
                  style={{ width: "100%", height: "80vh", lineHeight: "80vh" }}
                >
                  <h1>Coming soon</h1>
                </div> // Graceful handling for empty tracks
              )
            ) : (
              <div
                  style={{ width: "100%", height: "80vh", lineHeight: "80vh" }}
                >
                  <h1>Coming soon</h1>
                </div> 
            )}
          </div>
        </div>
        <CurrentClassesTracksModal
          currentUnit={classTypes[selectedKit].name}
          playAlongTracks={tracks}
          setSelectedPlayAlong={setSelectedNumberTrack}
          selectedPlayAlong={selectedNumberTrack}
          isOpen={isCurrentClassesTracksOpen}
          onClose={() => {
            setIsCurrentClassesTracksOpen(false);
          }}
        />
        <TracksModal
          currentUnit={currentUnitDisplay + 1}
          playAlongTracks={playAlongTracks}
          setSelectedPlayAlong={setSelectedPlayAlong}
          selectedPlayAlong={selectedPlayAlong}
          isOpen={isTracksOpen}
          onClose={() => {
            setIsTracksOpen(false);
          }}
        />
        <LessonVideos
          currentUnit={currentUnitDisplay + 1}
          isOpen={isLessonVideosOpen}
          setCurrentVideo={setCurrentVideo}
          setVideoIndex={setVideoIndex}
          videoIndex={videoIndex}
          videos={videos}
          onClose={() => {
            setIsLessonVideosOpen(false);
          }}
        />
        <CongratulationsModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
        <GoldenFiddle
          isOpen={goldenFiddleOpen}
          onClose={() => {
            setGoldenFiddleOpen(false);
            setSelectedTab(0);
            toggleSidebar();
          }}
          setSelectedKit={setSelectedKit}
          changeCourse={changeCourse}
        />
        <Calendar
          isGolden={
            goldenMembers && userEmail && goldenMembers.includes(userEmail)
          }
          isOpen={calendarOpen}
          onClose={() => {
            setCalendarOpen(false);
          }}
        />
      </div>
    </>
  );
}

export default Course;
