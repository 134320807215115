import React, { useEffect } from "react";

const CurrentClassesTracksModal = ({
  isOpen,
  onClose,
  playAlongTracks,
  selectedPlayAlong,
  setSelectedPlayAlong,
  currentUnit,
}) => {
  if (!isOpen) return null; // Only render if open
  return (
    <div className="modal-background">
      <div
        className="modal-container"
        style={{
          height: "85%",
          width: "85vw",
          maxWidth: 950,
          position: "relative",
        }}
      >
        <h2 className="modal-title" style={{ marginBottom: 20 }}>
          {currentUnit} Tracks
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            height: "80%",
            overflow: "scroll",
          }}
        >
          {playAlongTracks ? (
            Object.keys(playAlongTracks).map((item, index) => {
              const videoString = "video" + (index + 1);

              return (
                <div
                  className="track-item"
                  style={{
                    borderColor: "black",
                    borderStyle: "solid",
                    color: "white",
                    height: 50,
                    borderWidth: index == selectedPlayAlong ? 3.5 : 0,
                    fontWeight: index == selectedPlayAlong ? "700" : "normal",
                  }}
                  onClick={() => {
                    onClose();
                    setSelectedPlayAlong(index);
                  }}
                >
                  {playAlongTracks[index].title}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>

        <div style={{ position: "absolute", bottom: 40, right: 0, left: 0 }}>
          <button className="modal-button-close modal-button" onClick={onClose}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default CurrentClassesTracksModal;
